<template>
    <div class="whole-box">
        <div v-if="pastDue" class="pastDue-container">
            <img src="@/assets/image/login/quesheng_img_shixiao.png" alt="">
            <p class="lose-efficacy-link">链接已失效</p>
            <p class="lose-efficacy-tip">邀请链接已过期，请联系谢静用户重新邀请</p>
        </div>
        <div v-else class="login-container flex center">
            <div class="left" />
            <div class="right">
                <p class="invite-title">
                    Hi，{{info.username}}邀请您加入
                </p>
				<p class="invite-title" v-if="info.groupName">
					[ {{info.groupName}}小组 ]
				</p>
                <p class="invite-tip">
                    链接有效期: {{info.times}}
                </p>
                <div class="button confirm-button" @click="confirm(1)">加入小组</div>
                <div class="button cancel-button" @click="confirm(0)">不了，谢谢</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "invitation",
    data() {
        return {
            pastDue : false,            //是否过期
            info : {
                username : "",
                groupName : "",
                times : ""
            }
        }
    },
    created() {
        let { params: {code} } = this.$route;
		
        this.$api.team.invitaLinkInfo({invitaCode:code}).then(res=>{
            
            let {PeriodOfValidity:times} = res;
            times = times.replace(/00:00:00/ig,"")
            times = times.replace("~"," - ");
            res.times = times;
            let endTime = times.split(" - ")[1].trim();
            if( endTime ){
                endTime = (new Date(endTime)).getTime();
                let now = (new Date()).getTime();
                if( now - endTime < 0 ){
                    this.$set(this,"info",res)
                }else{
                    this.pastDue = true;
                }
            }
        })
    },
    methods: {
        /**
         * @description 是否加入确认,opinion这个值空，默认同意
         * @param opinion 1确认 0拒绝
         */
        confirm(opinion){
            if( opinion ){
                const {
                    group_id:groupId,
                    phone,
                    invita_code:invitaCode,
                    invita_user_id:invitaUserId,
                    groupName
                } = this.info;
                let params = {
                    groupId,phone,invitaCode,invitaUserId,groupName,opinion,admin:0
                }
                this.$api.team.addInvitaUser(params).then(res=>{
                    if( opinion ){
                        this.$router.push({
                            name : "login"
                        })
                    }else{
                        //js只能关闭代码打开的标签页
                        if(navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1){
                            window.location.href = "about:blank";
                            window.close();
                        }else{
                            window.opener = null;
                            window.open("", "_self");
                            window.close();
                        }
                    }
                })
            }else{
                window.opener=null;
                window.open('','_self');
                window.close();
                window.open("about:blank",'_self');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.whole-box {
    min-height: 750px;
	width: 1400px;
	margin: 0 auto;
	height: 100vh;
	position: relative;
    .pastDue-container {
		height: 100%;
        text-align: center;
        img {
            margin: 310px auto 34px;
			width: 224px;
			height: 160px;
        }
        .lose-efficacy-link {
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #383D41;
            line-height: 30px;
        }
        .lose-efficacy-tip {
            margin-top: 12px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #60707D;
            line-height: 20px;
        }
    }
    .login-container {
        width: 100%;
        // min-height: 800px;
        height: 100%;
        background: url('../../assets/image/login/login_bg.png') no-repeat;
        background-size: cover;
        .left {
            width: 550px;
            height: 150px;
            margin-right: 186px;
            background: url('../../assets/image/login/login_img_logo.png') no-repeat;
            background-size: 100%;
        }
        .right {
            position: relative;
            width: 480px;
            min-height: 310px;
			background: #FFFFFF;
			box-shadow: 0px 20px 70px 0px rgba(179,215,252,0.6), 0px 0px 15px 0px rgba(197,224,252,0.4);
			border-radius: 16px;
			padding: 40px;
            // padding: 47px 40px 40px;
            // background: #FFFFFF;
            // box-shadow: 0px 20px 70px 0px rgba(179, 215, 252, 0.6), 0px 0px 15px 0px rgba(197, 224, 252, 0.4);
            // border-radius: 2px;
            // text-align: center;
            .invite-title {
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #383D41;
                line-height: 33px;
            }
            .invite-tip {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #60707D;
                line-height: 20px;
				margin-top: 9px;
            }
            .button {
                width: 400px;
                height: 55px;
                border-radius: 8px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                line-height: 55px;
                cursor: pointer;
				text-align: center;
                &.confirm-button {
                    background: #006AFF;
                    margin-top: 38px;
                    font-weight: 600;
                    color: #FFFFFF;
                }
                &.cancel-button {
					color: #383D41;
                    margin-top: 20px;
                    background: #FFFFFF;
                    border: 1px solid #C3CFD9;
                }
            }
        }
    }
}
</style>